import React, { useState } from 'react';
import { navigate } from 'gatsby';
import qs from 'qs';
import moment from 'moment';
import 'moment/locale/fr';
import locale from 'antd/es/date-picker/locale/fr_FR';
import debounce from 'lodash/debounce';
import { LoadingOutlined } from '@ant-design/icons';
import {
  Form, Input, Radio, Checkbox, DatePicker, Select, notification,
} from 'antd';
import Cookies from 'universal-cookie';
import API from 'api';
import { centerCookieIds, countriesList } from 'utils/constants';

const { Option } = Select;

const RegisterForm = (props) => {
  const { selectedCenter, setUserLoggedIn, location } = props;
  const [form] = Form.useForm();
  const [zipOptions, setZipOptions] = useState([]);
  const [zipOptionsFetching, setZipOptionsFetching] = useState(false);
  const [registerLoading, setRegisterLoading] = useState(false);
  const cookies = new Cookies(); 

  const [email_pref, setEmail_pref] = useState(false);
  const [SMS_pref, setSMS_pref] = useState(false);
  const [date_consentement, setDate_consentement] = useState(null);

  

  const onChangeEmailPref = (e) => {
    setEmail_pref(e.target.value);
  };

  const onChangeSMSPref = (e) => {
    setSMS_pref(e.target.value);
  };

  const onSubmit = (values) => {
    const registerValues = {
      ...values,
      civility: values.civility,
      phone: values.phone || '',
      phone_mobile: values.phone_mobile || '',
      birthdate: moment(values.birthdate).format('MM/DD/YYYY'),
      interests: null,
      sms_ok: SMS_pref || false,
      mail_ok: email_pref || false,
      post_ok: (SMS_pref || email_pref) || false,
      session: cookies.get(centerCookieIds[selectedCenter.centerId].cookieName),
      address_complement: (values.address_complement == null) ? "not_completed" : values.address_complement,
      centre:window.location.hostname.split(".")[0],
      operationnelles_ok:true,
      promotions_ok:true,
      actualites_ok:true,
      rgpd_ok:true
    };
    setRegisterLoading(true);
    API.post('/ssfhjtuf', qs.stringify(registerValues), { centerId: selectedCenter.centerId }).then((res) => {
      if (res.data.status === 1) {
        
        localStorage.setItem(centerCookieIds[selectedCenter.centerId].cookieName, cookies.get(centerCookieIds[selectedCenter.centerId].cookieName));
        // setErrorMessage('');
        setUserLoggedIn(res.data.responseData.userContent);
        setRegisterLoading(false);
        if (location?.state?.checkout) {
          navigate('/checkout');
        } else {
          navigate('/mon-compte');
        }
      } else if (res.data.status === 3) {
        notification.error({
          message: "L'email existe déjà",
        });
        setRegisterLoading(false);
      } else {
        setRegisterLoading(false);
      }
    });
    console.log(registerValues);
  };

  const findZipcodeOid = (val) => {
    setZipOptionsFetching(true);
    API.get(`/zipcode/search/${val}`).then((res) => {
      setZipOptionsFetching(false);
      setZipOptions(res.data.responseData);
    });
  };

  const debouncedFindZipcodeOid = debounce(findZipcodeOid, 200);

  const changeZipcode = (e) => {
    if (e.target.value) {
      if(e.target.value.length === 5) {
        debouncedFindZipcodeOid(e.target.value);
      }
    }
  };

  const validateMessages = {
    required: 'Un champ est manquant.',
  };

  return (
    <div className="register-form--section">
      <div className="register-form--wrapper container">
        <div className="register-form">
          <h3 className="register-form--title">Mes informations</h3>
          <Form
            initialValues={{ civility: '1' }}
            validateMessages={validateMessages}
            layout="vertical"
            form={form}
            name="control-hooks"
            onFinish={onSubmit}
          >
            <Form.Item className="horizontal-form-row" name="civility" label="Civilité : ">
              <Radio.Group>
                <Radio value="1">M.</Radio>
                <Radio value="2">Mme.</Radio>
                <Radio value="6">Entreprise</Radio>
              </Radio.Group>
            </Form.Item>
            <div className="form-row--wrapper">
              <Form.Item
                className="registration-form-field"
                name="lastname"
                label="Nom"
                validateTrigger="onSubmit"
                rules={[{ required: true }]}
              >
                <Input autoComplete="new-lastname" />
              </Form.Item>
              <Form.Item
                name="firstname"
                label="Prénom"
                className="registration-form-field"
                validateTrigger="onSubmit"
                rules={[{ required: true }]}
              >
                <Input autoComplete="new-firstname" />
              </Form.Item>
            </div>
            <div className="form-row--wrapper">
              <Form.Item
                name="mail"
                className="registration-form-field"
                label="Email"
                validateTrigger="onSubmit"
                rules={
                  [
                    { required: true },
                    {
                      pattern: new RegExp(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/),
                      message: `L\'entrée n\'est pas un e-mail valide`,
                    },
                    
                  ]
                }
              >
                <Input autoComplete="new-mail"/>
              </Form.Item>
              <Form.Item
                name="password"
                label={<span>Mot de passe <span style={{ fontSize: "0.5rem" }}>(Le mot de passe doit contenir 10 caractères, une majuscule et un caractère spécial minimum)</span></span>}
                validateTrigger="onSubmit"
                rules={[
                        { required: true, message: 'Le champ mot de passe n\'est pas saisi' },
                        {
                          pattern: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{10,}$/,
                          message: 'Le mot de passe doit contenir 10 caractères, un chiffre, une majuscule et un caractère minimum',
                        }
                      ]}
              >
                <Input.Password autoComplete="new-password" />
              </Form.Item>
            </div>
            
            <div className="form-row--wrapper">
              <Form.Item
                name="phone"
                className="registration-form-field"
                label="Téléphone fixe"
                validateTrigger="onSubmit"
                rules={[
                  // { required: true },  
                  
                  ({ getFieldValue }) => ({
                    validator(rule, value) {
                      if (value || (!value && getFieldValue('phone_mobile') !== undefined && getFieldValue('phone_mobile') !== '')) {
                        if(value) {
                          if(value[1] == 6 || value[1] == 7) {
                            return Promise.reject('Votre numéro est invalide');
                          }else {
                            return Promise.resolve();
                          }
                        }else {
                          return Promise.resolve();
                        }
                      }
                      return Promise.reject('Entrez au moins un des numéros de téléphone');
                    },
                  }),
                ]}
              >
                <Input autoComplete="new-phone" />
              </Form.Item>
              <Form.Item
                name="phone_mobile"
                className="registration-form-field"
                label="Téléphone mobile"
                validateTrigger="onSubmit"
                rules={[
                  ({ getFieldValue }) => ({
                    validator(rule, value) {
                      if (value || (!value && getFieldValue('phone') !== undefined && getFieldValue('phone') !== '')) {
                        if(value) {
                          if(value[1] != 6 && value[1] != 7) {
                            return Promise.reject('Votre numéro est invalide');
                          }else {
                            return Promise.resolve();
                          }
                        }else {
                          return Promise.resolve();
                        }
                        
                      }
                      return Promise.reject('Entrez au moins un des numéros de téléphone');
                    },
                  }),
                ]}
              >
                <Input autoComplete="new-mobile" />
              </Form.Item>
            </div>
            <div>
              <p className="tel_obl">Entrez au moins un des numéros de téléphone</p>
            </div>
            <div className="form-row--wrapper">
              <Form.Item
                className="registration-form-field"
                name="birthdate"
                label="Date de naissance"
                rules={[{ required: true, type: 'object' }]}
              >
                <DatePicker locale={locale} format="DD/MM/YYYY" placeholder="Choisir une date" />
              </Form.Item>
            </div>
            <div className="form-row--wrapper">
              <Form.Item
                name="address"
                label="Adresse"
                className="registration-form-field"
                validateTrigger="onSubmit"
                rules={[{ required: true }]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="address_complement"
                label="Complément d'adresse"
                className="registration-form-field"
                validateTrigger="onSubmit"
                rules={[{ required: false }]}
              >
                <Input autoComplete="new-address" />
              </Form.Item>
            </div>
            <div className="form-row--wrapper">
              <Form.Item
                name="zipcode"
                className="registration-form-field"
                label="Code postal"
                onChange={changeZipcode}
                validateTrigger="onSubmit"
                rules={[{ required: true }]}
              >
                <Input autoComplete="new-zipcode" />
              </Form.Item>
              <Form.Item
                name="zipcode_oid"
                label="Ville"
                className="registration-form-field"
                rules={[{ required: true, message: 'Veuillez choisir votre ville' }]}
              >
                <Select
                  loading={zipOptionsFetching}
                  showSearch
                  disabled={zipOptionsFetching || !zipOptions?.length}
                  placeholder=""
                  filterOption={
                    (input, option) => option.children.toLowerCase().includes(
                      input.toLowerCase(),
                    )
                  }
                >
                  {zipOptions?.map((option) => (
                    <Option key={`${option.oid}`} value={option.oid}>
                      {option.commune.nomMaj}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </div>
            <div className="form-row--wrapper">
              <Form.Item
                name="pays"
                label="Pays"
                className="registration-form-field"
                rules={[{ required: true }]}
              >
                <Select
                  showSearch
                  allowClear
                  placeholder=""
                  filterOption={
                    (input, option) => option.children.toLowerCase().includes(
                      input.toLowerCase(),
                    )
                  }
                >
                  {countriesList.map((option) => (
                    <Option key={`${option.oid}`} value={option.oid}>
                      {option.libelle}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </div>
            
            <div className="pref_comm">
              <h3 className="register-form--title">Mes préférences de communication</h3>
              <p>Je souhaite recevoir des informations personnalisées de la part de Calicéo :</p>
              <div className="une_ligne">
                <p>Par e-mail : </p>
                <div>
                  <Radio.Group onChange={onChangeEmailPref} value={email_pref} name={"mail_ok"}>
                    <Radio value={true}>Oui</Radio>
                    <Radio value={false}>Non</Radio>
                  </Radio.Group>
                </div>
              </div>

              <div className="une_ligne">
                <p>Par SMS : </p>
                <div>
                  <Radio.Group onChange={onChangeSMSPref} value={SMS_pref} name={"sms_ok"}>
                    <Radio value={true}>Oui</Radio>
                    <Radio value={false}>Non</Radio>
                  </Radio.Group>
                </div>
              </div>

              
            </div>

            <Form.Item>
              <button type="submit" disabled={registerLoading} className="primary-caliceo--button mt-5">
                {registerLoading ? (
                  <LoadingOutlined className="button-loading-icon" />
                ) : (
                  `M'inscrire`
                )}
              </button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default RegisterForm;
