import React, { useEffect, useState } from 'react';
import { navigate } from 'gatsby';
import userIsLoggedIn from 'utils/checkLoggedIn';
import { getCenterById } from 'utils/getCentersBy';
import { getCenterName } from 'utils/location';
import useSetCenterCookie from 'hooks/use-set-center-cookie';
import RegisterForm from './RegisterForm';
import './register.scss';

const Register = (props) => {
  const { centers, actions, location } = props;
  const [centerObj, setCenterObj] = useState(null);

  const centerName = getCenterName(props.location);

  useEffect(() => {
    setCenterObj(getCenterById(centers, `centre-${centerName}`));
  }, [centers, centerName]);

  useSetCenterCookie(`centre-${centerName}`);

  useEffect(() => {
    if (userIsLoggedIn(`centre-${centerName}`)) {
      navigate('/mon-compte');
    }
  }, [centerName]);

  return (
    <div>
      <div className="register-page--header container mt-5">
        {centerObj && (
          <h3>{centerObj?.name}</h3>
        )}
        <h1>Entrez dans la bulle Calicéo</h1>
      </div>
      <RegisterForm location={location} setUserLoggedIn={actions.setUserLoggedIn} selectedCenter={centerObj} />
    </div>
  );
};

export default Register;
