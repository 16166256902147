import React from 'react';

import Layout from 'components/layout';
import Register from 'components/register';
import Reinsurance from 'components/home-group/reinsurance/Reinsurance';

const RegisterPage = (props) => {
  return (
    <Layout location={props.location}>
      <Register />
      <Reinsurance location={props.location} />
    </Layout>
  );
};

export default RegisterPage;
