import { connect } from 'react-redux';

import { bindActionCreators } from 'redux';

import { setUserLoggedIn } from 'redux/actions/auth';
import { selectCenters } from 'redux/selectors/centers';

const mapStateToProps = (state) => ({
  centers: selectCenters(state),
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    setUserLoggedIn,
  }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps);
